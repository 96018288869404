import React from 'react'
import styles from './formInputFile.module.css'

const FormInputFile = props => {
  return (
    <div className='form-group' style={{ marginBottom: '0px' }}>
      <label htmlFor={props.id} className={styles.btnBootstrap}>
        {props.text}
      </label>
      <input
        id={props.id}
        type='file'
        tabIndex='0'
        multiple={props.multiple}
        onChange={props.onChange}
      />
    </div>
  )
}

export default FormInputFile
