import React, { useEffect, useState } from 'react'
import {
  getPatientAndTherapyByTerapeuta,
  getTerapeutaById
} from '../../../utils/serverRequest'
import DashboardTitle from '../../dashboardTitle/dashboardTitle'
import { TableBasicInfo } from './TableBasicInfo'
import Avatar from '../../Globales/avatar/avatar'
import Grilla from '../../Globales/grilla/grilla'
import styles from './dashboardInfoTerapeuta.module.css'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { parseDateAAAAMMDDtoDDMMAAAA } from '../../../utils/utils'
import { FaExternalLinkAlt, FaRegEdit } from 'react-icons/fa'
import EditForm from '../../editarTerapeuta/editForm'

export default function DashboardInfoTerapeuta (props) {
  const history = useHistory()
  const id = props.match.params.id
  const isEdit = props.location?.state?.isEdit
  const terap = props.location?.state?.terapeuta

  const [terapeuta, setTerapeuta] = useState(terap || null)
  const [patientsTherapy, setPatientsTherapy] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isEditing, setEditing] = useState(!!isEdit)

  useEffect(() => {
    handleFetchData()
  }, [terap])

  const handleFetchData = async () => {
    try {
      const [terapeutaData, patientsAndTherapyData] = await Promise.all([
        !terap ? getTerapeutaById(id) : null,
        getPatientAndTherapyByTerapeuta(id)
      ])

      if (terapeutaData?.ok) setTerapeuta(terapeutaData.terapeuta)
      if (patientsAndTherapyData?.ok)
        setPatientsTherapy(patientsAndTherapyData.data)
    } catch (e) {
      console.log('Error al traer data del terapeuta: ', e)
    } finally {
      setLoading(false)
    }
  }

  if (isLoading)
    return (
      <main>
        <h4 style={{ marginTop: 30 }}>Cargando...</h4>
      </main>
    )

  if (!terapeuta)
    return (
      <main>
        <h4 style={{ marginTop: 30 }}>
          No se encontro el terapeuta con id {id}
        </h4>
      </main>
    )

  const arrayPatients =
    patientsTherapy?.map((data, key) => {
      const patient = data.paciente
      const therapy = data.terapia

      return (
        <tr key={key}>
          <th scope='row'>
            <Avatar rutaAvatar={patient.avatar} />
          </th>
          <td>{patient.dni}</td>
          <td>
            <Link
              to={{ pathname: `/panel/pacientes/perfil/${patient._id}` }}
              className={styles.link_info}
            >
              {patient.nombreCompleto}
            </Link>
          </td>
          <td>{parseDateAAAAMMDDtoDDMMAAAA(patient.fechaNacimiento)}</td>
          <td>{patient.obraSocial}</td>
          <td style={{ color: therapy?.colorHex, fontWeight: 'bold' }}>
            <Link
              to={{ pathname: `/panel/terapias/perfil/${therapy._id}` }}
              style={{
                backgroundColor: therapy?.colorHex,
                outline: '2px solid ' + therapy?.colorHex
              }}
              className={styles.link_therapy}
            >
              {therapy.titulo}
            </Link>
          </td>
          <td>
            <Link
              to={{ pathname: `/panel/pacientes/perfil/${patient._id}` }}
              style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
            >
              <FaExternalLinkAlt color='#0079e1' size={20} />
            </Link>
          </td>
        </tr>
      )
    }) || null

  const handleFinishEdit = newTerapeuta => {
    history.replace({
      pathname: '/panel/terapeutas/' + id, // La ruta actual o la ruta donde deseas que se actualice el estado
      state: { ...props.location.state, terapeuta: newTerapeuta } // Actualizamos el estado
    })
    setTerapeuta(newTerapeuta)
    setEditing(false)
  }

  return (
    <main className='dashboardContainer'>
      <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
        <Avatar rutaAvatar={terapeuta.avatar} size='xl' />
        <DashboardTitle title={terapeuta.nombreCompleto} />
      </div>

      <section style={{ margin: '30px 0', position: 'relative' }}>
        {!isEditing && (
          <FaRegEdit
            onClick={() => setEditing(!isEditing)}
            size={25}
            style={{
              position: 'absolute',
              right: 0,
              top: -50,
              cursor: 'pointer'
            }}
            color='#0079e1'
          />
        )}

        {isEditing ? (
          <EditForm
            terapeuta={terapeuta}
            handleCancel={() => {
              setEditing(false)
              //ir al top de la pagina
              window.scrollTo(0, 0)
            }}
            handleFinishEdit={handleFinishEdit}
          />
        ) : (
          <TableBasicInfo terapeuta={terapeuta} />
        )}
      </section>

      {!isEditing && (
        <section style={{ marginTop: 30 }}>
          <h3 style={{ textAlign: 'left' }}>Pacientes</h3>
          {arrayPatients?.length > 0 ? (
            <Grilla
              titulos={[
                'Avatar',
                'Documento',
                'Nombre Completo',
                'Fecha de Nacimiento',
                'Obra Social',
                'Terapia',
                'Acciones'
              ]}
              filas={arrayPatients}
            />
          ) : (
            <h5 style={{ paddingTop: 20 }}>No hay pacientes asignados</h5>
          )}
        </section>
      )}
    </main>
  )
}
