import { verificarTokenUsuario } from './serverRequest'
import _ from 'underscore'

const accents = {
  á: 'a',
  é: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u',
  Á: 'A',
  É: 'E',
  Í: 'I',
  Ó: 'O',
  Ú: 'U'
}

const checkToken = () => {
  if (localStorage.getItem('token')) {
    return true
  } else {
    return false
  }
}

const verificarToken = async () => {
  let data = await verificarTokenUsuario()
  return data.ok
}

const stillLoggedIn = async () => {
  let tokenVerificado
  let hasToken = checkToken()
  if (hasToken) {
    tokenVerificado = await verificarToken()
    return tokenVerificado
  } else {
    return false
  }
}

const differenceArrayObjectsWithId = (arr1, arr2) => {
  let finalIds = _.difference(
    arr1.map(i => i._id),
    arr2.map(i => i.terapeuta._id)
  )
  let finalArray = arr1.filter(i => _.contains(finalIds, i._id))

  return finalArray
}

const differenceArrayObjectsWithId2 = (arr1, arr2) => {
  let finalIds = _.difference(
    arr1.map(i => i._id),
    arr2.map(i => i._id)
  )
  let finalArray = arr1.filter(i => _.contains(finalIds, i._id))

  return finalArray
}

const isImage = fileName => {
  let extension = fileName
    .substring(fileName.lastIndexOf('.') + 1)
    .toLowerCase()

  switch (extension) {
    case 'jpg':
    case 'png':
    case 'jpeg':
      return true
    default:
      return false
  }
}

const parseDateDDMMAAAAtoAAAAMMDD = date => {
  try {
    if (!date) throw new Error('Date is empty')
    const split = date.split('/')
    if (split.length !== 3) throw new Error('Invalid date format length')
    return `${split[2]}-${split[1]}-${split[0]}`
  } catch (error) {
    //console.log('Error in parseDateDDMMAAAAtoAAAAMMDD: ', error)
    return date
  }
}

const parseDateAAAAMMDDtoDDMMAAAA = date => {
  try {
    if (!date) throw new Error('Date is empty')
    const split = date.split('-')
    if (split.length !== 3) throw new Error('Invalid date format length')
    return `${split[2]}/${split[1]}/${split[0]}`
  } catch (error) {
    //console.log('Error in parseDateAAAAMMDDtoDDMMAAAA: ', error)
    return date
  }
}

const normalizeWithoutAccents = word =>
  word.replace(/[áéíóúÁÉÍÓÚ]/g, letra => accents[letra] || letra)

export {
  checkToken,
  verificarToken,
  stillLoggedIn,
  differenceArrayObjectsWithId,
  differenceArrayObjectsWithId2,
  isImage,
  parseDateDDMMAAAAtoAAAAMMDD,
  parseDateAAAAMMDDtoDDMMAAAA,
  normalizeWithoutAccents
}
