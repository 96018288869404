import React, { useState } from 'react'
import styles from '../signUp/signUp.module.css'
//Libreria Yup para validaciones del formulario
import * as yup from 'yup'
//funcion para Updatear nuevos usuarios
import { updateTerapeuta, uploadUserAvatar } from '../../utils/serverRequest'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import FormContainer from '../Globales/formContainer/FormContainer'
import FormInput from '../Globales/formInput/FormInput'
import FormButton from '../Globales/formButton/FormButton'
import InputMessage from '../Globales/inputMessage/InputMessage'
import FormInputFile from '../Globales/formInputFile/FormInputFile'
import Avatar from '../Globales/avatar/avatar'
import { MdClose } from 'react-icons/md'
import Alert from '../Globales/alertPropia/alertPropia'
import {
  parseDateAAAAMMDDtoDDMMAAAA,
  parseDateDDMMAAAAtoAAAAMMDD
} from '../../utils/utils'
import ModalPropio from '../Globales/modal/modalPropio'

// const roles = ['Admin', 'Terapeuta']

let validationSchemaSignup = yup.object().shape({
  nombreCompleto: yup.string().required('Este campo es requerido'),
  fechaNacimiento: yup.date().required('Este campo es requerido'),
  dni: yup.string().required('Este campo es requerido'),
  email: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('Este campo es requerido')
})

const EditForm = ({ terapeuta, handleCancel, handleFinishEdit }) => {
  let history = useHistory()
  const [serverErrorDescription, setServerErrorDescription] = useState('')
  const [avatar, setAvatar] = useState({
    current: terapeuta?.avatar || '',
    new: ''
  })
  const [alertImage, setAlertImage] = useState(false)
  const [viewModalAccept, setViewModalAccept] = useState(false)
  const [response, setResponse] = useState(null)

  const parseDate = parseDateDDMMAAAAtoAAAAMMDD(terapeuta?.fechaNacimiento)

  const { handleChange, handleSubmit, values, errors, isSubmitting } =
    useFormik({
      initialValues: {
        nombreCompleto: terapeuta?.nombreCompleto || '',
        fechaNacimiento: parseDate || '',
        dni: terapeuta?.dni || '',
        email: terapeuta?.email || '',
        _id: terapeuta?._id || ''
      },
      validationSchema: validationSchemaSignup,
      validateOnBlur: true,
      validateOnChange: false,
      onSubmit: async () => {
        setViewModalAccept(true)
      }
    })

  const handleAccept = async values => {
    setViewModalAccept(false)

    const res = await updateTerapeuta({
      ...values,
      fechaNacimiento: parseDateAAAAMMDDtoDDMMAAAA(values.fechaNacimiento)
    })

    switch (res.status) {
      case 200:
        let responseUpdateAvatar = null
        if (avatar.new) {
          responseUpdateAvatar = await enviarArchivo(res.data.terapeuta._id)
          if (!responseUpdateAvatar.ok) alert('No se pudo crear la foto')
        }

        setResponse({
          ok: true,
          message: handleFinishEdit
            ? 'Terapeuta editado correctamente'
            : "Terapeuta editado correctamente, redirigiendo a 'Terapeutas'..."
        })

        const newAv = responseUpdateAvatar?.ok
          ? responseUpdateAvatar.user.avatar
          : res.data.terapeuta.avatar

        !handleFinishEdit
          ? setTimeout(() => {
              setResponse(null)
              history.push('/panel/terapeutas')
            }, 2000)
          : handleFinishEdit({
              ...res.data.terapeuta,
              avatar: newAv
            })
        break
      case 400:
        if (res.data.err.dni && res.data.err.email) {
          setServerErrorDescription(
            res.data.err.dni.message + ' ' + res.data.err.email.message
          )
        } else if (res.data.err.dni) {
          setServerErrorDescription(res.data.err.dni.message)
        } else {
          setServerErrorDescription(
            res.data.err.email.message || 'Hubo un error 400'
          )
        }
        break
      case 500:
        console.log(res.data.err)
        setServerErrorDescription('Hubo un error inesperado del servidor')
        break
      default:
        console.log(res)
        break
    }

    if (serverErrorDescription) {
      setTimeout(() => {
        setServerErrorDescription('')
      }, 2500)
    }
  }

  const addImage = e => {
    e.preventDefault()
    let nuevoAvatar = e.target.files[0]
    if (isImage(nuevoAvatar.name)) {
      setAvatar({
        ...avatar,
        new: nuevoAvatar
      })
    } else {
      setAlertImage(true)
      setTimeout(() => {
        setAlertImage(false)
      }, 5000)
    }
    // Para poder volver a cargar el mismo archivo después de sacarlo
    e.target.value = ''
  }

  const isImage = fileName => {
    let extension = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase()
    switch (extension) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return true
      default:
        return false
    }
  }

  const removeImage = e => {
    e.preventDefault()
    setAvatar({ current: '', new: '' })
  }

  const enviarArchivo = async userId => {
    let formData = new FormData()
    formData.append('Archivos', avatar.new)
    let data = await uploadUserAvatar(formData, userId)
    return data
  }

  return (
    <div className='col-lg-6 col-md-6 col-sm-9 col-xs-12 container'>
      {alertImage && (
        <Alert variant='danger' show={alertImage}>
          <strong>Archivo no válido.</strong> Seleccione un archivo con
          extensión <strong>JPG</strong>, <strong>JPEG</strong> o{' '}
          <strong>PNG</strong>
        </Alert>
      )}

      {response && (
        <Alert variant='success' show={response.ok}>
          <strong>{response.message}</strong>
        </Alert>
      )}

      {serverErrorDescription && (
        <Alert variant='danger' show={!!serverErrorDescription}>
          <strong>{serverErrorDescription}</strong>
        </Alert>
      )}

      <FormContainer title='Editar usuario' clicked={handleSubmit}>
        <FormInput
          labelText='Nombre completo'
          labelHtmlFor='nombreCompleto'
          atrName='nombreCompleto'
          atrId='nombreCompleto'
          atrType='text'
          atrValue={values.nombreCompleto}
          changed={handleChange}
          hasErrors={errors.nombreCompleto ? true : false}
        >
          {errors.nombreCompleto ? (
            <InputMessage
              message={errors.nombreCompleto}
              atrId='hint_nombreCompleto'
              hasErrors={errors.nombreCompleto ? true : false}
            />
          ) : null}
        </FormInput>

        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <FormInput
              labelText='Fecha de nacimiento'
              labelHtmlFor='fechaNacimiento'
              atrName='fechaNacimiento'
              atrId='fechaNacimiento'
              atrType='date'
              atrValue={values.fechaNacimiento}
              changed={handleChange}
              hasErrors={errors.fechaNacimiento ? true : false}
            >
              {errors.fechaNacimiento ? (
                <InputMessage
                  message={errors.fechaNacimiento}
                  atrId='hint_fechaNacimiento'
                  hasErrors={errors.fechaNacimiento ? true : false}
                />
              ) : null}
            </FormInput>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <FormInput
              labelText='Documento'
              labelHtmlFor='dni'
              atrName='dni'
              atrId='dni'
              atrType='text'
              atrValue={values.dni}
              changed={handleChange}
              hasErrors={errors.dni ? true : false}
            >
              {errors.dni ? (
                <InputMessage
                  message={errors.dni}
                  atrId='hint_dni'
                  hasErrors={errors.dni ? true : false}
                />
              ) : null}
            </FormInput>
          </div>
        </div>

        <FormInput
          labelText='Rol'
          labelHtmlFor='rol'
          atrName='rol'
          atrId='rol'
          atrType='text'
          atrValue={terapeuta?.rol || ''}
          changed={handleChange}
          hasErrors={errors.rol ? true : false}
          disabled={true}
        >
          {errors.rol ? (
            <InputMessage
              message={errors.rol}
              atrId='hint_rol'
              hasErrors={errors.rol ? true : false}
            />
          ) : null}
        </FormInput>
        <FormInput
          labelText='Correo electrónico'
          labelHtmlFor='email'
          atrName='email'
          atrId='email'
          atrType='text'
          atrMinLength='8'
          atrValue={values.email}
          changed={handleChange}
          hasErrors={errors.email ? true : false}
        >
          {errors.email ? (
            <InputMessage
              message={errors.email}
              atrId='hint_email'
              hasErrors={errors.email ? true : false}
            />
          ) : null}
        </FormInput>

        <div
          className={'row ' + styles.containerAvatarMax}
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '24px' }}
        >
          <div className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}>
            {!avatar.current && !avatar.new && (
              <FormInputFile
                text='SUBIR FOTO DE PERFIL'
                multiple={false}
                id='archivoAvatar'
                onChange={addImage}
              />
            )}
          </div>
          {avatar.new || avatar.current ? (
            <div
              className={'col-xl-4 col-lg-6 col-12 ' + styles.avatarContainer}
            >
              <div className={styles.avatar}>
                <MdClose color='red' onClick={removeImage} />
              </div>
              <Avatar
                rutaAvatar={
                  avatar.new
                    ? URL.createObjectURL(avatar.new)
                    : avatar.current
                    ? avatar.current
                    : ''
                }
                size='l'
              />
            </div>
          ) : (
            <div className={'col-xl-8 col lg-6 col-12 ' + styles.noHayAvatar}>
              <ul
                style={{
                  paddingLeft: '0px',
                  marginBottom: '0px',
                  listStyleType: 'none'
                }}
              >
                <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                  Aún no ha seleccionado una foto de perfil
                </p>
              </ul>
            </div>
          )}
        </div>

        {/* {serverError && (
          <InputMessage
            message={serverErrorDescription}
            atrId='hint_sverror'
            hasErrors={serverError ? true : false}
          />
        )} */}
        <div
          className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}
          style={{ marginTop: '32px' }}
        >
          <FormButton
            text='EDITAR'
            buttonType='submit'
            atrName='edit'
            buttonStyle='primario'
            atrDisabled={isSubmitting}
          />
          {handleCancel && (
            <FormButton
              text='CANCELAR'
              buttonType='button'
              atrName='cancel'
              buttonStyle='secundario'
              clicked={handleCancel}
            />
          )}
        </div>
      </FormContainer>

      <ModalPropio
        title='Editar Terapeuta'
        content={`¿Está seguro que quiere editar al terapeuta ${terapeuta.nombreCompleto}?`}
        isOpen={viewModalAccept}
        closeModal={setViewModalAccept}
        okHandler={() => handleAccept(values)}
        isSubmitting={isSubmitting}
        danger={true}
        botonPrimario='Aceptar'
        botonSecundario='Cancelar'
      />
    </div>
  )
}

export default EditForm
