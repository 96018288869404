import React from 'react'
import { parseDateAAAAMMDDtoDDMMAAAA } from '../../../utils/utils'
import styles from './tableBasicInfo.module.css'

export const TableBasicInfo = ({ terapeuta }) => {
  const splitDate = terapeuta.createdAt.split('T')[0]

  return (
    <div className={styles.container}>
      <div>
        <Item title='Nombre Completo' value={terapeuta.nombreCompleto} />
        <Item title='Email' value={terapeuta.email} />
      </div>
      <div>
        <Item title='Documento de Identidad' value={terapeuta.dni} />
        <Item title='Rol' value={terapeuta.rol} />
      </div>
      <div>
        <Item
          title='Fecha de Nacimiento'
          value={parseDateAAAAMMDDtoDDMMAAAA(terapeuta.fechaNacimiento)}
        />
        <Item
          title='Creacion de Cuenta'
          value={parseDateAAAAMMDDtoDDMMAAAA(splitDate)}
        />
      </div>
    </div>
  )
}

const Item = ({ title, value }) => {
  return (
    <div>
      <h3>{title}</h3>
      <p>{value}</p>
    </div>
  )
}
