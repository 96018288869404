import axios from 'axios'
import qs from 'qs'

let token

const obtenerTerapias = async () => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      url: '/api/terapias',
      method: 'get'
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const obtenerPaciente = async pacienteId => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'GET',
      url: `/api/paciente/${pacienteId}`,
      headers: {
        token
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const modificarPaciente = async paciente => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/paciente/${paciente.nuevoPaciente._id}`,
      data: paciente
    })

    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const verificarTokenUsuario = async () => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'get',
      url: '/api/user/verificacion-token',
      headers: {
        token
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const verifyChangePasswordToken = async token => {
  try {
    const res = await axios({
      method: 'get',
      url: '/api/user/verificacion-token',
      headers: {
        token
      }
    })

    return res
  } catch (error) {
    return error.response
  }
}

const obtenerObrasSociales = async () => {
  token = localStorage.getItem('token')
  let res = await axios('/api/obrasSociales', {
    headers: {
      token
    }
  })

  return res.data
}

const obtenerPacientes = async () => {
  token = localStorage.getItem('token')
  let res = await axios({
    method: 'GET',
    url: '/api/pacientes',
    headers: {
      token
    }
  })

  return res.data
}

const obtenerTerapeutasPreview = async () => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'GET',
      headers: {
        token
      },
      url: '/api/terapeutas-preview'
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const obtenerTerapeutas = async () => {
  token = localStorage.getItem('token')
  let res = await axios({
    method: 'GET',
    url: '/api/terapeutas',
    headers: {
      token
    }
  })
  return res.data
}

const getTerapeutaById = async id => {
  if (!id) return null

  token = localStorage.getItem('token')

  let res = await axios({
    method: 'GET',
    url: '/api/terapeutas/' + id,
    headers: {
      token
    }
  })
  return res.data
}

const getPatientAndTherapyByTerapeuta = async id => {
  if (!id) return null

  token = localStorage.getItem('token')

  const res = await axios({
    method: 'GET',
    url: `/api/get-patient-therapy-by-terapeuta/${id}`,
    headers: { token }
  })

  return res.data
}

const crearUsuario = async nuevoUsuario => {
  try {
    let res = await axios({
      method: 'post',
      url: '/api/signup',
      data: {
        ...nuevoUsuario
      }
    })
    return res
  } catch (err) {
    return err.response
  }
}

const updateTerapeuta = async data => {
  token = localStorage.getItem('token')
  try {
    const res = await axios({
      method: 'patch',
      url: '/api/update-terapeuta',
      headers: {
        token
      },
      data
    })

    console.log('RESPONSE updateTerapeuta: ', res)

    return res
  } catch (err) {
    return err.response
  }
}

const crearPaciente = async nuevoPaciente => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'POST',
      headers: {
        token
      },
      data: nuevoPaciente,
      url: '/api/pacientes'
    })

    return res.data
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const loginUsuario = async usuario => {
  try {
    let res = await axios({
      method: 'post',
      url: '/api/login',
      data: {
        ...usuario
      }
    })
    return res
  } catch (err) {
    return err.response
  }
}

const eliminarTerapeuta = async terapeutaId => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'delete',
      headers: {
        token
      },
      url: `/api/terapeuta/${terapeutaId}`
    })
    return res.data
  } catch (err) {
    console.log(err)
    return err.response
  }
}

const bajarTerapeuta = async terapeutaId => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'delete',
      headers: {
        token
      },
      url: `/api/terapeuta-baja-logica/${terapeutaId}`
    })
    return res.data
  } catch (err) {
    console.log(err)
    return {
      ok: false,
      err: 'No se pudo eliminar al terapeuta'
    }
  }
}

const borrarTerapias = async terapiaId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      method: 'delete',
      url: `/api/terapia/${terapiaId}`
    })
    return res
  } catch (err) {
    return err.response
  }
}

const bajarTerapia = async terapiaId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'DELETE',
      headers: {
        token
      },
      url: `/api/terapia-baja-logica/${terapiaId}`
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al crear la terapia'
    }
  }
}

const crearTerapia = async terapia => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      headers: {
        token
      },
      url: '/api/terapias/creacion',
      data: {
        ...terapia
      }
    })
    return res
  } catch (err) {
    return err.response
  }
}

const editarTerapia = async (terapiaId, terapia) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      method: 'put',
      url: '/api/terapia/' + terapiaId,
      data: {
        ...terapia
      }
    })
    return res
  } catch (err) {
    return err.response
  }
}

const eliminarPaciente = async pacienteId => {
  try {
    token = localStorage.getItem('token')
    let res = await axios({
      method: 'DELETE',
      headers: {
        token
      },
      url: `/api/paciente/${pacienteId}`
    })
    return res.data
  } catch (err) {
    return {
      ok: false
    }
  }
}

const obtenerPacientesPaginados = async (page, valorBusqueda) => {
  token = localStorage.getItem('token')
  let res = await axios({
    method: 'GET',
    headers: {
      token
    },
    url: `/api/pacientes/${page}`,
    params: {
      valorBusqueda
    }
  })
  return res.data
}

const obtenerAprobaciones = async usuario => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'get',
      headers: {
        token
      },
      url: '/api/aprobaciones'
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const aprobarSolicitudUsuario = async usuario => {
  token = localStorage.getItem('token')
  console.log('usuario', usuario)
  try {
    let res = await axios({
      method: 'put',
      headers: {
        token
      },
      url: `/api/habilitar-usuario/${usuario._id}`,
      data: { rol: usuario.rol }
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const aprobarSolicitudAcceso = async accesoId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'put',
      headers: {
        token
      },
      url: `/api/habilitar-acceso/${accesoId}`
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const desaprobarSolicitudUsuario = async usuarioId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'delete',
      headers: {
        token
      },
      url: `/api/rechazar-usuario/${usuarioId}`
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const desaprobarSolicitudAcceso = async accesoId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'delete',
      headers: {
        token
      },
      url: `/api/deshabilitar-acceso/${accesoId}`
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const solicitarAccesos = async accesos => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      headers: {
        token
      },
      url: '/api/solicitar-accesos',
      data: {
        accesos
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const comentarPostPaciente = async (nuevoComentario, idPaciente) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      url: `/api/comentario-paciente/${idPaciente}`,
      headers: {
        token
      },
      data: {
        ...nuevoComentario
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const comentarPostTerapia = async (nuevoComentario, idTerapia) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      url: `/api/comentario-terapia/${idTerapia}`,
      headers: {
        token
      },
      data: {
        ...nuevoComentario
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const crearPostPaciente = async nuevoPost => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      url: '/api/post-paciente',
      headers: {
        token
      },
      data: {
        ...nuevoPost
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const crearPostTerapia = async nuevoPost => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'post',
      url: '/api/post-terapia',
      headers: {
        token
      },
      data: {
        ...nuevoPost
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const obtenerPostsPaciente = async (
  pacienteId,
  page,
  terapiaId,
  queryParams
) => {
  token = localStorage.getItem('token')
  let endpoint
  if (terapiaId === '') {
    endpoint = `/api/posts-paciente/${pacienteId}/${page}`
  } else {
    endpoint = `/api/posts-paciente/${pacienteId}/${page}/?terapiaId=${terapiaId}`
  }

  try {
    let res = await axios({
      method: 'GET',
      headers: {
        token
      },
      url: `/api/posts-paciente/${pacienteId}/${page}`,
      params: {
        ...queryParams,
        terapiaId: terapiaId || ''
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}
const obtenerPostsTerapia = async (terapiaId, page, queryParams) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'GET',
      headers: {
        token
      },
      url: `/api/posts-terapia/${terapiaId}/${page}`,
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    return res.data
  } catch (err) {
    return err.response
  }
}
const obtenerInfoTerapia = async terapiaId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'GET',
      url: `/api/terapia-foro/${terapiaId}`,
      headers: {
        token
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const obtenerInfoPacientes = async pacienteId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'GET',
      url: `/api/paciente-foro/${pacienteId}`,
      headers: {
        token
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const obtenerComentarios = async (postId, page) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'GET',
      url: `/api/comentarios/${postId}/${page}`,
      headers: {
        token
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}
const borrarPost = async postId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      method: 'delete',
      url: `/api/post/${postId}`
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const borrarComentario = async comentarioId => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      method: 'delete',
      url: `/api/comentario/${comentarioId}`
    })
    return res.data
  } catch (err) {
    return err.response
  }
}

const obtenerTerapiasTerapeutaPaciente = async (terapeutaId, pacienteId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'get',
      headers: {
        token
      },
      url: `/api/terapias/${terapeutaId}/${pacienteId}`
    })
    return res
  } catch (err) {
    return {
      ok: false,
      err: err.response
    }
  }
}

const uploadPacienteAvatar = async (formData, pacienteId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios.post(
      `/api/load-paciente-avatar/${pacienteId}`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
          token
        }
      }
    )
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al cargar el avatar del paciente'
    }
  }
}

const uploadPacienteArchivos = async (formData, pacienteId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios.post(
      `/api/load-paciente-archivos/${pacienteId}`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
          token
        }
      }
    )
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al cargar los archivos del usuario'
    }
  }
}

const uploadUserAvatar = async (formData, userId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios.post(`/api/load-user-avatar/${userId}`, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al cargar el avatar del usuario'
    }
  }
}

const reuploadPacienteArchivos = async (formData, pacienteId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios.put(
      `/api/load-paciente-archivos/${pacienteId}`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
          token
        }
      }
    )
    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al cargar los archivos del usuario'
    }
  }
}

const uploadPostFiles = async (formData, postId) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios.post(`/api/load-post-files/${postId}`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        token
      }
    })
    return res.data
  } catch (error) {
    return {
      ok: false,
      err: 'Error al cargar los archivos del Post'
    }
  }
}

const obtenerPostPaciente = async (postId, pacienteId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'get',
      headers: {
        token
      },
      url: `/api/post-paciente/${postId}/${pacienteId}`
    })
    return res
  } catch (err) {
    return err.response
  }
}

const obtenerPostTerapia = async (postId, terapiaId) => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      method: 'get',
      headers: {
        token
      },
      url: `/api/post-terapia/${postId}/${terapiaId}`
    })
    return res
  } catch (err) {
    return err.response
  }
}

const enviarFeedback = async feedback => {
  token = localStorage.getItem('token')
  try {
    let res = await axios({
      headers: {
        token
      },
      method: 'post',
      url: '/api/feedback',
      data: {
        ...feedback
      }
    })
    return res.data
  } catch (err) {
    return {
      ok: false,
      err
    }
  }
}

const crearPostReaccion = async (postId, reaccion) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'POST',
      headers: {
        token
      },
      url: '/api/crear-reaccion-post',
      data: {
        postId,
        reaccion
      }
    })

    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al generar la reacción'
    }
  }
}

const getNotifications = async page => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'GET',
      headers: {
        token
      },
      url: `/api/notifications/${page}`
    })

    return res
  } catch (error) {
    return error.response
  }
}

const markNotificationRead = async id => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/mark-notification-read/${id}`
    })

    return res
  } catch (error) {
    return error.response
  }
}

const markNotificationsRead = async () => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/mark-all-notifications-read`
    })

    return res
  } catch (error) {
    return error.response
  }
}

const eliminarPostReaccion = async (postReaccionId, postId) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'DELETE',
      headers: {
        token
      },
      url: `/api/delete-post-reaccion/${postReaccionId}/${postId}`
    })

    return res.data
  } catch (error) {
    return {
      ok: false,
      error
    }
  }
}

const updatePost = async (postId, data) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/post/${postId}`,
      data
    })

    return res
  } catch (error) {
    return error.response
  }
}

const updateComment = async (commentId, data) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/comentario/${commentId}`,
      data
    })

    return res
  } catch (error) {
    return error.response
  }
}

const getUnreadNotification = async () => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'GET',
      headers: {
        token
      },
      url: '/api/unread-notification'
    })

    return res
  } catch (error) {
    return error.response
  }
}

const crearComentarioReaccion = async (comentarioId, reaccion) => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'POST',
      headers: {
        token
      },
      url: '/api/crear-reaccion-comentario',
      data: {
        comentarioId,
        reaccion
      }
    })

    return res.data
  } catch (err) {
    return {
      ok: false,
      err: 'Error al generar la reacción'
    }
  }
}

const eliminarComentarioReaccion = async comentarioReaccionId => {
  token = localStorage.getItem('token')

  try {
    let res = await axios({
      method: 'DELETE',
      headers: {
        token
      },
      url: `/api/delete-comentario-reaccion/${comentarioReaccionId}`
    })

    return res.data
  } catch (error) {
    return {
      ok: false,
      error
    }
  }
}

const forgotPassword = async email => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/forgot-password',

      data: {
        email
      }
    })

    return res
  } catch (error) {
    return error.response
  }
}

const getAdminList = async conditions => {
  token = localStorage.getItem('token')
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/admin',
      headers: {
        token
      },
      data: {
        conditions
      }
    })

    return res
  } catch (error) {
    return error.response
  }
}

const updateUser = async (data, userId) => {
  token = localStorage.getItem('token')

  try {
    const res = await axios({
      method: 'PUT',
      headers: {
        token
      },
      url: `/api/user/${userId}`,
      data: {
        ...data
      }
    })

    return res
  } catch (error) {
    return error.response
  }
}

const changePassword = async (newPassword, userId, passwordToken) => {
  try {
    const res = await axios({
      method: 'PUT',
      headers: {
        token: passwordToken
      },
      url: `/api/change-user-password/${userId}`,
      data: {
        newPassword
      }
    })

    return res
  } catch (error) {
    return error.response
  }
}

export {
  crearUsuario,
  obtenerTerapeutas,
  obtenerTerapeutasPreview,
  eliminarTerapeuta,
  eliminarPaciente,
  obtenerTerapias,
  obtenerPacientes,
  obtenerPacientesPaginados,
  verificarTokenUsuario,
  loginUsuario,
  obtenerAprobaciones,
  aprobarSolicitudUsuario,
  aprobarSolicitudAcceso,
  desaprobarSolicitudUsuario,
  desaprobarSolicitudAcceso,
  obtenerObrasSociales,
  editarTerapia,
  borrarTerapias,
  crearTerapia,
  modificarPaciente,
  crearPaciente,
  obtenerPaciente,
  solicitarAccesos,
  obtenerTerapiasTerapeutaPaciente,
  uploadPacienteAvatar,
  uploadUserAvatar,
  uploadPacienteArchivos,
  reuploadPacienteArchivos,
  borrarPost,
  borrarComentario,
  comentarPostTerapia,
  comentarPostPaciente,
  crearPostPaciente,
  crearPostTerapia,
  obtenerComentarios,
  obtenerInfoTerapia,
  obtenerInfoPacientes,
  obtenerPostsTerapia,
  obtenerPostsPaciente,
  bajarTerapeuta,
  uploadPostFiles,
  obtenerPostPaciente,
  obtenerPostTerapia,
  bajarTerapia,
  enviarFeedback,
  getNotifications,
  markNotificationRead,
  markNotificationsRead,
  updatePost,
  updateComment,
  crearPostReaccion,
  eliminarPostReaccion,
  getUnreadNotification,
  crearComentarioReaccion,
  eliminarComentarioReaccion,
  forgotPassword,
  verifyChangePasswordToken,
  getAdminList,
  updateUser,
  changePassword,
  updateTerapeuta,
  getTerapeutaById,
  getPatientAndTherapyByTerapeuta
}
