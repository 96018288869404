import React from 'react'
import PropTypes from 'prop-types'
import Box from '../box/Box'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import styles from './ConfirmationSignUp.module.css'

const ConfirmationSignUp = props => {
  return (
    <div className='col-lg-6 col-md-6 col-sm-9 col-xs-12 container'>
      <Box>
        <Image
          src={props.imageSrc || '/images/confirmationIllustration.svg'}
          className={styles.imgClass}
          alt='ilustracion'
        />
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h4 className={styles.title}>
                {props.title || 'El usuario se ha creado con éxito'}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className={styles.content}>
                Para poder ingresar a la plataforma, el administrador del sitio
                deberá <strong>aprobar la solicitud de acceso.</strong>
              </p>
              <p className={styles.content}>
                Cuando se haya aprobado la solicitud,
                <strong> recibirás un mail</strong> al correo electrónico
                ingresado.
              </p>
              <p className={styles.content}>
                <Link to='/ingresar'>
                  <a href='#'>Volver al inicio</a>
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </Box>
    </div>
  )
}

ConfirmationSignUp.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ConfirmationSignUp

// /images/confirmationIllustration.svg
// El usuario se ha creado con éxito
