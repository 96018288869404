import React, { useEffect, useState } from 'react'

import InputMessage from '../Globales/inputMessage/InputMessage'
import FormInput from '../Globales/formInput/FormInput'
import FormStep from '../Globales/formStep/formStep'
import FormStepSubtitle from '../Globales/formStepSubtitle/formStepSubtitle'
import FormTextArea from '../Globales/formTextArea/FormTextArea'
import FormSelect from '../Globales/formSelect/FormSelect'
import FormInputFile from '../Globales/formInputFile/FormInputFile'
import Avatar from '../Globales/avatar/avatar'
import { obtenerObrasSociales } from '../../utils/serverRequest'
import { MdClose } from 'react-icons/md'
import FilePreview from '../Globales/filePreview/filePreview'
import styles from '../signUp/signUp.module.css'

const generos = ['Femenino', 'Masculino']

const Step1 = props => {
  const [obrasSociales, setObrasSociales] = useState([])

  useEffect(() => {
    const getObrasSociales = async () => {
      let data = await obtenerObrasSociales()

      setObrasSociales(data.obrasSociales)
    }
    getObrasSociales()
  }, [])

  return (
    <FormStep stepsHandler={props.aumentarDecrementarPaso} first={true}>
      <FormStepSubtitle title='Información General' />
      <div className='row'>
        <div className='col-12'>
          <FormInput
            labelText='Nombre completo (*)'
            labelHtmlFor='nombreCompleto'
            atrName='nombreCompleto'
            atrId='nombreCompleto'
            atrType='text'
            atrValue={props.values.nombreCompleto}
            changed={props.handleChange}
            hasErrors={props.errors.nombreCompleto ? true : false}
          >
            {props.errors.nombreCompleto ? (
              <InputMessage
                message={props.errors.nombreCompleto}
                atrId='hint_nombreCompleto'
                hasErrors={props.errors.nombreCompleto ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Número de documento (*)'
            labelHtmlFor='dni'
            atrName='dni'
            atrId='dni'
            atrType='text'
            atrValue={props.values.dni}
            changed={props.handleChange}
            hasErrors={props.errors.dni ? true : false}
          >
            {props.errors.dni ? (
              <InputMessage
                message={props.errors.dni}
                atrId='hint_dni'
                hasErrors={props.errors.dni ? true : false}
              />
            ) : null}
          </FormInput>
        </div>

        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Fecha de nacimiento (*)'
            labelHtmlFor='fechaNacimiento'
            atrName='fechaNacimiento'
            atrId='fechaNacimiento'
            atrType='date'
            atrValue={props.values.fechaNacimiento}
            atrPlaceholder='DD/MM/AAAA'
            changed={props.handleChange}
            hasErrors={props.errors.fechaNacimiento ? true : false}
          >
            {props.errors.fechaNacimiento ? (
              <InputMessage
                message={props.errors.fechaNacimiento}
                atrId='hint_fechaNacimiento'
                hasErrors={props.errors.fechaNacimiento ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Nacionalidad (*)'
            labelHtmlFor='nacionalidad'
            atrName='nacionalidad'
            atrId='nacionalidad'
            atrType='text'
            atrValue={props.values.nacionalidad}
            changed={props.handleChange}
            hasErrors={props.errors.nacionalidad ? true : false}
          >
            {props.errors.nacionalidad ? (
              <InputMessage
                message={props.errors.nacionalidad}
                atrId='hint_nacionalidad'
                hasErrors={props.errors.nacionalidad ? true : false}
              />
            ) : null}
          </FormInput>
        </div>

        <div className='col-md-6 col-12'>
          <FormSelect
            labelText='Género (*)'
            labelHtmlFor='genero'
            atrName='genero'
            atrId='genero'
            atrType='text'
            atrValue={props.values.genero}
            changed={props.handleChange}
            hasErrors={props.errors.genero ? true : false}
            list={generos}
          >
            {props.errors.genero ? (
              <InputMessage
                message={props.errors.genero}
                atrId='hint_genero'
                hasErrors={props.errors.genero ? true : false}
              />
            ) : null}
          </FormSelect>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <FormInput
            labelText='Domicilio (*)'
            labelHtmlFor='direccion'
            atrName='direccion'
            atrId='direccion'
            atrType='text'
            atrValue={props.values.direccion}
            changed={props.handleChange}
            hasErrors={props.errors.direccion ? true : false}
          >
            {props.errors.direccion ? (
              <InputMessage
                message={props.errors.direccion}
                atrId='hint_direccion'
                hasErrors={props.errors.direccion ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Localidad (*)'
            labelHtmlFor='localidad'
            atrName='localidad'
            atrId='localidad'
            atrType='text'
            atrValue={props.values.localidad}
            changed={props.handleChange}
            hasErrors={props.errors.localidad ? true : false}
          >
            {props.errors.localidad ? (
              <InputMessage
                message={props.errors.localidad}
                atrId='hint_localidad'
                hasErrors={props.errors.localidad ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Código postal (*)'
            labelHtmlFor='codigoPostal'
            atrName='codigoPostal'
            atrId='codigoPostal'
            atrType='text'
            atrValue={props.values.codigoPostal}
            changed={props.handleChange}
            hasErrors={props.errors.codigoPostal ? true : false}
          >
            {props.errors.codigoPostal ? (
              <InputMessage
                message={props.errors.codigoPostal}
                atrId='hint_codigoPostal'
                hasErrors={props.errors.codigoPostal ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Teléfono (1)'
            labelHtmlFor='telefono1'
            atrName='telefono1'
            atrId='telefono1'
            atrType='text'
            atrValue={props.values.telefono1}
            changed={props.handleChange}
            hasErrors={props.errors.telefono1 ? true : false}
          >
            {props.errors.telefono1 ? (
              <InputMessage
                message={props.errors.telefono1}
                atrId='hint_telefono1'
                hasErrors={props.errors.telefono1 ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Teléfono (2)'
            labelHtmlFor='telefono2'
            atrName='telefono2'
            atrId='telefono2'
            atrType='text'
            atrValue={props.values.telefono2}
            changed={props.handleChange}
            hasErrors={props.errors.telefono2 ? true : false}
          >
            {props.errors.telefono2 ? (
              <InputMessage
                message={props.errors.telefono2}
                atrId='hint_telefono2'
                hasErrors={props.errors.telefono2 ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>
      <div
        className='row'
        style={{ textAlign: 'left', marginTop: '8px', marginBottom: '32px' }}
      >
        <div className='col-12'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={props.values.estaEscolarizado}
              name='estaEscolarizado'
              id='estaEscolarizado'
              onChange={props.handleCheckEscolarizado}
            />
            <label
              className='form-check-label'
              htmlFor='estaEscolarizado'
              style={{ fontSize: '16px', fontWeight: '400' }}
            >
              ¿El paciente está escolarizado?
            </label>
          </div>
        </div>
        {props.values.estaEscolarizado && (
          <div className='col-12' style={{ marginTop: '16px' }}>
            <FormTextArea
              labelText='Información escolar'
              labelHtmlFor='informacionEscolar'
              atrName='informacionEscolar'
              atrId='informacionEscolar'
              atrType='text'
              atrValue={props.values.informacionEscolar}
              changed={props.handleChange}
            />
          </div>
        )}
      </div>

      {/* FROM SIGNUP, editing */}

      <div
        className={'row ' + styles.containerAvatarMax}
        style={{ marginLeft: '0px', marginRight: '0px', marginTop: '24px' }}
      >
        <div className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}>
          <FormInputFile
            text='SUBIR FOTO DE PERFIL'
            multiple={false}
            id='archivoAvatar'
            onChange={props.addImage}
          />
        </div>
        {props.avatar ? (
          <div className={'col-xl-4 col-lg-6 col-12 ' + styles.avatarContainer}>
            <div className={styles.avatar}>
              <MdClose color='red' onClick={props.removeImage} />
            </div>
            <Avatar
              rutaAvatar={
                typeof props.avatar === 'string'
                  ? props.avatar
                  : URL.createObjectURL(props.avatar)
              }
              size='l'
            />
          </div>
        ) : props.newAvatar ? (
          <div className={'col-xl-4 col-lg-6 col-12 ' + styles.avatarContainer}>
            <div className={styles.avatar}>
              <MdClose onClick={props.removeImage} color='red' />
            </div>
            <Avatar
              rutaAvatar={URL.createObjectURL(props.newAvatar)}
              size='l'
            />
          </div>
        ) : (
          <div className={'col-xl-8 col lg-6 col-12 ' + styles.noHayAvatar}>
            <ul
              style={{
                paddingLeft: '0px',
                marginBottom: '0px',
                listStyleType: 'none'
              }}
            >
              <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                Aún no ha seleccionado una foto de perfil
              </p>
            </ul>
          </div>
        )}
      </div>

      <FormStepSubtitle title='Datos de la obra social' />

      <div className='row'>
        <div className='col-12'>
          <FormSelect
            labelText='Obra social (*)'
            labelHtmlFor='obraSocial'
            atrName='obraSocial'
            atrId='obraSocial'
            atrValue={props.values.obraSocial}
            changed={props.handleChange}
            hasErrors={props.errors.obraSocial ? true : false}
            list={obrasSociales}
          >
            {props.errors.obraSocial ? (
              <InputMessage
                message={props.errors.obraSocial}
                atrId='hint_obraSocial'
                hasErrors={props.errors.obraSocial ? true : false}
              />
            ) : null}
          </FormSelect>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <FormInput
            labelText='Nombre que figura en la tarjeta (*)'
            labelHtmlFor='nombreTitular'
            atrName='nombreTitular'
            atrId='nombreTitular'
            atrType='text'
            atrValue={props.values.nombreTitular}
            changed={props.handleChange}
            hasErrors={props.errors.nombreTitular ? true : false}
          >
            {props.errors.nombreTitular ? (
              <InputMessage
                message={props.errors.nombreTitular}
                atrId='hint_nombreTitular'
                hasErrors={props.errors.nombreTitular ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <FormInput
            labelText='Número de la tarjeta (*)'
            labelHtmlFor='numeroTarjeta'
            atrName='numeroTarjeta'
            atrId='numeroTarjeta'
            atrType='text'
            atrValue={props.values.numeroTarjeta}
            changed={props.handleChange}
            hasErrors={props.errors.numeroTarjeta ? true : false}
          >
            {props.errors.numeroTarjeta ? (
              <InputMessage
                message={props.errors.numeroTarjeta}
                atrId='hint_numeroTarjeta'
                hasErrors={props.errors.numeroTarjeta ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Fecha de vencimiento'
            labelHtmlFor='fechaVencimiento'
            atrName='fechaVencimiento'
            atrId='fechaVencimiento'
            atrType='text'
            atrValue={props.values.fechaVencimiento}
            atrPlaceholder='MM/AA'
            changed={props.handleChange}
            hasErrors={props.errors.fechaVencimiento ? true : false}
          >
            {props.errors.fechaVencimiento ? (
              <InputMessage
                message={props.errors.fechaVencimiento}
                atrId='hint_fechaVencimiento'
                hasErrors={props.errors.fechaVencimiento ? true : false}
              />
            ) : null}
          </FormInput>
        </div>

        <div className='col-md-6 col-12'>
          <FormInput
            labelText='Código de seguridad'
            labelHtmlFor='codigoSeguridad'
            atrName='codigoSeguridad'
            atrId='codigoSeguridad'
            atrType='text'
            atrValue={props.values.codigoSeguridad}
            changed={props.handleChange}
            hasErrors={props.errors.codigoSeguridad ? true : false}
          >
            {props.errors.codigoSeguridad ? (
              <InputMessage
                message={props.errors.codigoSeguridad}
                atrId='hint_codigoSeguridad'
                hasErrors={props.errors.codigoSeguridad ? true : false}
              />
            ) : null}
          </FormInput>
        </div>
      </div>
      <FormStepSubtitle title='Archivos adjuntos' />

      <div className='row' styles={{ marginBottom: '16px' }}>
        <div className='col-xl-4 col-lg-6 col-12'>
          <FormInputFile
            text='SUBIR ARCHIVOS'
            id='archivos'
            multiple={true}
            onChange={props.addArchivos}
          />
        </div>
        <div className='col-lg-8 col-12' style={{ alignSelf: 'center' }}>
          <ul
            className={styles.listFilePreview}
            style={{
              paddingLeft: '0px',
              marginBottom: '0px',
              listStyleType: 'none'
            }}
          >
            {props.archivos.length > 0 ? (
              props.archivos.map((file, key) => (
                <li key={key}>
                  <FilePreview
                    file={file}
                    originalLink={file.originalLink}
                    presignedUrl={file.presignedUrl}
                    link={true}
                    handleRemoverArchivo={props.handleRemoverArchivo}
                  />
                </li>
              ))
            ) : (
              <></>
            )}

            {props.newArchivos && props.newArchivos.length > 0 ? (
              props.newArchivos.map((file, key) => (
                <li key={key}>
                  <FilePreview
                    file={file}
                    presignedUrl={file}
                    link={false}
                    handleRemoverArchivo={props.handleRemoverNewArchivo}
                  />
                </li>
              ))
            ) : (
              <></>
            )}

            {props.archivos.length === 0 && !props.newArchivos ? (
              <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                Aún no ha seleccionado archivos
              </p>
            ) : props.archivos.length === 0 &&
              props.newArchivos.length === 0 ? (
              <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                Aún no ha seleccionado archivos
              </p>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </FormStep>
  )
}

export default Step1
