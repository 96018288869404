import React from 'react'

// Components

import DashboardTitle from '../dashboardTitle/dashboardTitle'
import SignUp from '../signUp/SignUp'

const abmTerapeuta = props => {
  console.log('PROPS abmTerapeuta: ', props)

  return (
    <div>
      <DashboardTitle
        title={
          props.creacion ? 'Creación de Terapeuta' : 'Modificación de Terapeuta'
        }
      />
      <SignUp />
    </div>
  )
}

export default abmTerapeuta
