import React, { useState, useEffect, useContext } from 'react';
import {UserContext} from '../context/context';
import { obtenerPostPaciente, borrarPost } from '../../utils/serverRequest';

// Components
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import BoxInfoFeedPaciente from '../Globales/boxInfoFeedPaciente/BoxInfoFeedPaciente';
import FeedMoreInformation from '../Globales/feedMoreInformation/FeedMoreInformation';
import Spinner from '../Globales/spinner/spinner';
import Post from '../Globales/post/Post';
import ModalPropio from '../Globales/modal/modalPropio';
import Alert from '../Globales/alertPropia/alertPropia';
import BackButton from '../Globales/backButton/BackButton';
import EmptyState from '../Globales/emptyState/emptyState';

const PostPagePaciente = props => {
  const [isLoading, setSpinner] = useState(true);
  const [dataPost, setDataPost] = useState({});
  const [pageSeleccionada, setPageSeleccionada] = useState('foro');
  const [dataPaciente, setDataPaciente] = useState({
    nombreCompleto: '',
    avatar: '',
    fechaNacimiento: '',
    dni: '',
    id: '',
    obraSocial: '',
    terapiasActivas: [],
    responsableNombre: '',
    responsableTelefono: '',
    terapiasTerapeutaEscritura: [],
    terapiasTerapeutaLectura: [],
    accesos: {}
  });
  const [isOpenModalEliminarPost, setModalEliminarPost] = useState(false);
  const [postSeleccionadoId, setPostSeleccionadoId] = useState('');
  const [isSubmittingDeletePost, setSubmitDeletePost] = useState(false);
  const [isOpenAlertEliminar, setAlertEliminar] = useState(false);
  const [isOpenAlertFail, setAlertFail] = useState(false);
  const [statusError, setStatusError] = useState(0)

  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;
  const emailUsuario = user.email;

  useEffect(() => {
    let postId = props.match.params.postId;
    let pacienteId = props.match.params.pacienteId;
  
    const getPostPaciente = async () => {
      let res = await obtenerPostPaciente(postId, pacienteId);
      if (res.status === 200) {
        let nuevoPaciente = {
          id: res.data.paciente._id,
          nombreCompleto: res.data.paciente.nombreCompleto,
          dni: res.data.paciente.dni,
          avatar: res.data.paciente.avatar || '',
          obraSocial: res.data.paciente.obraSocial,
          fechaNacimiento: res.data.paciente.fechaNacimiento,
          responsableNombre: res.data.paciente.responsable.nombreCompleto,
          responsableTelefono: res.data.paciente.responsable.telefono1,
          terapiasActivas: res.data.paciente.terapias,
          archivos: res.data.paciente.archivos,
          terapiasTerapeutaEscritura: res.data.paciente.terapiasTerapeutaEscritura,
          terapiasTerapeutaLectura: res.data.paciente.terapiasTerapeutaLectura,
          accesos: res.data.paciente.accesos
        };
        setDataPaciente(nuevoPaciente);
        if (res.data.post === undefined) {
          setDataPost({});
        } else {
          setDataPost(res.data.post);
        }
      } 
      else if (res.status === 404) {
        setStatusError(404)
      }
      else {
        alert('Error al cargar el post');
      }
      setSpinner(false);
    };
    getPostPaciente();
  }, [props.match.params.postId]);

  const handlePage = id_page => {
    let idPage = id_page;
    setPageSeleccionada(idPage);
  };

  const handleModalEliminarPost = postId => {
    setModalEliminarPost(true);
    setPostSeleccionadoId(postId);
  };

  const deletePost = async postId => {
    setSubmitDeletePost(true);
    let data = await borrarPost(postId);
    if (data.ok) {
      setModalEliminarPost(false);
      setDataPost({});
      setAlertEliminar(true);
      setTimeout(() => {
        setAlertEliminar(false);
      }, 3000);
    } else {
      setModalEliminarPost(false);
      setAlertFail(true);
      setTimeout(() => {
        setAlertFail(false);
      }, 3000);
    }
    setSubmitDeletePost(false);
  };

  const handleRedirectToForoPaciente = () => {
    if (
      rolUsuario === 'Terapeuta'
      && dataPaciente.terapiasTerapeutaEscritura.length === 0
      && dataPaciente.terapiasTerapeutaLectura.length === 0
    ) {
      props.history.push({
        pathname: `/panel/pacientes`
      });
    } else {
      props.history.push({
        pathname: `/panel/pacientes/perfil/${props.match.params.pacienteId}`
      });
    }
  };

  return (
    <div className='dashboardContainer'>
      {isOpenAlertEliminar && (
        <Alert variant='success' show={isOpenAlertEliminar}>
          <strong>Eliminación exitosa.</strong> Se ha eliminado el post de forma
          correcta.
        </Alert>
      )}
      {isOpenAlertFail && (
        <Alert variant='danger' show={isOpenAlertFail}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar el
          post. Intente nuevamente.
        </Alert>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <BackButton onClick={handleRedirectToForoPaciente} />
        <DashboardTitle title='Información del post' />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
          <>
            {rolUsuario === 'Terapeuta'
              && dataPaciente.terapiasTerapeutaEscritura.length === 0
              && dataPaciente.terapiasTerapeutaLectura.length === 0 ? (
                <div style={{ marginTop: '56px' }}>
                {
                  statusError === 404 ? <EmptyState ruta='/images/vacio.svg' subtitulo={`Este post fue borrado`}/> 
                  : <EmptyState ruta='/images/acceso_denegado.svg' subtitulo={`No tenés acceso a ver el perfil del paciente ${dataPaciente.nombreCompleto}`}/> 
                }
                </div>
              ) : (
                <>
                  <div
                    className='col-xl-9 col-12 container'
                    style={{ padding: '0px 0px 0px 0px' }}
                  >
                    <BoxInfoFeedPaciente
                      avatarPaciente={dataPaciente.avatar}
                      nombrePaciente={dataPaciente.nombreCompleto}
                      terapiasTerapeutaEscritura={
                        dataPaciente.terapiasTerapeutaEscritura
                      }
                      terapiasTerapeutaLectura={
                        dataPaciente.terapiasTerapeutaLectura
                      }
                      clicked={handlePage}
                      paginaSeleccionada={pageSeleccionada}
                    />
                  </div>
                  {pageSeleccionada === 'foro' ? (
                    <div
                      className='col-xl-9 col-12 container'
                      style={{ padding: '0px 0px 0px 0px' }}
                    >
                      {dataPost.hasOwnProperty('creador') === false ? (
                        <div style={{ marginTop: '56px' }}>
                          <EmptyState
                            ruta='/images/vacio.svg'
                            subtitulo='El post al que estas intentando ingresar no existe o se ha eliminado.'
                          />
                        </div>
                      ) : (
                          <Post
                            feed='pacientes'
                            idPaciente={props.match.params.pacienteId}
                            avatarTerapeuta={dataPost.creador.avatar}
                            idCreador={dataPost.creador._id}
                            nombreTerapeuta={dataPost.creador.nombreCompleto}
                            antiguedadPost={dataPost.fechaCreacion}
                            textoPost={dataPost.contenido}
                            rawContent={dataPost.rawContent}
                            imagenesPost={dataPost.imagenes || []}
                            videosPost={dataPost.videos || []}
                            archivosPost={dataPost.archivos || []}
                            nombreTerapia={dataPost.terapia.titulo}
                            colorTerapia={dataPost.terapia.colorHex}
                            idPost={dataPost._id}
                            modalEliminar={handleModalEliminarPost}
                            tieneComentarios={dataPost.cantidadComentarios > 0 ? true : false}
                            cantidadComentarios={dataPost.cantidadComentarios}
                            possibleMentions={dataPost.possibleMentions}
                            views={dataPost.views}
                            isViewed={dataPost.views && dataPost.views.some(v => v.viewer.id === user.id)}
                            reacciones={dataPost.reacciones || []} // Las que tiene el post [[':smile:', [PostReaccion]], [':open_mouth', [PostReaccion], ...n]
                            reaccionesPosibles={dataPost.reaccionesPosibles || []} // [Reaccion] --> Las reacciones 'puras' (Con titulo, emoji code)
                            dioReaccion={dataPost.dioReaccion}
                            reaccionDada={dataPost.reaccionDada}
                            postReaccion={dataPost.postReaccion}
                            defaultReaction={dataPost.defaultReaction}
                            cantidadReacciones={dataPost.cantidadReacciones}
                          />
                        )}
                    </div>
                  ) : (
                      <div
                        className='col-xl-9 col-12 container'
                        style={{ padding: '0px 0px 0px 0px' }}
                      >
                        <FeedMoreInformation data={dataPaciente} />
                      </div>
                    )}
                </>
              )}
          </>
        )}
      {isOpenModalEliminarPost && (
        <ModalPropio
          title='Eliminar post'
          content={
            '¿Está seguro que quiere eliminar el post? También se borraran los comentarios asociados al mismo.'
          }
          isOpen={isOpenModalEliminarPost}
          danger={true}
          closeModal={setModalEliminarPost}
          okHandler={() => deletePost(postSeleccionadoId)}
          isSubmitting={isSubmittingDeletePost}
          botonPrimario='Eliminar'
          botonSecundario='Cancelar'
        />
      )}
    </div>
  );
};

export default PostPagePaciente;
