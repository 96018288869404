//Packages & Utils
import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../context/context'
import styles from './dashboardTerapeuta.module.css'
import { obtenerTerapeutas, bajarTerapeuta } from '../../utils/serverRequest'

// Components
import DashboardTitle from '../dashboardTitle/dashboardTitle'
import Buscador from '../Globales/buscador/buscador.jsx'
import Grilla from '../Globales/grilla/grilla.jsx'
import Avatar from '../Globales/avatar/avatar.jsx'
import Paginacion from '../Globales/paginacion/paginacion.jsx'
import Spinner from '../Globales/spinner/spinner.jsx'
import { IoMdTrash } from 'react-icons/io'
import ModalPropio from '../Globales/modal/modalPropio'
import Alert from '../Globales/alertPropia/alertPropia'
import EmptyState from '../Globales/emptyState/emptyState.jsx'
import { Animated } from 'react-animated-css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaRegEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js'
import { parseDateAAAAMMDDtoDDMMAAAA } from '../../utils/utils.js'

const DashboardTerapeuta = () => {
  //State
  const [terapeutasIniciales, setTerapeutasIniciales] = useState([])
  const [valorBusqueda, setValorBusqueda] = useState('')
  const [paginaActual, setPaginaActual] = useState(1)
  const [isLoading, setSpinner] = useState(true)
  const [modalEliminar, setModalEliminar] = useState(false)
  const [terapeutaSeleccionado, setTerapeutaSeleccionado] = useState('')
  const [alertSuccess, setAlertSucces] = useState(false)
  const [alertFail, setAlertFail] = useState(false)
  const [isSubmittingDelete, setSubmitDelete] = useState(false)

  useEffect(() => {
    let traerTerapeutas = async () => {
      let data = await obtenerTerapeutas()
      if (data.ok) {
        setSpinner(false)
        setTerapeutasIniciales(data.terapeutas)
      } else {
        setSpinner(false)
        alert('Error al importar los terapeutas')
      }
    }
    traerTerapeutas()
  }, [])

  // Context
  const [user, setUser] = useContext(UserContext)
  const rolUsuario = user.rol

  // Vars
  const cantidadTotalTerapeutas = terapeutasIniciales.filter(
    terapeuta =>
      terapeuta.nombreCompleto
        .toLowerCase()
        .indexOf(valorBusqueda.toLocaleLowerCase()) !== -1
  ).length
  const cantidadPaginas = Math.ceil(cantidadTotalTerapeutas / 12)
  let arrayFilas = terapeutasIniciales
    .filter(
      terapeuta =>
        terapeuta.nombreCompleto
          .toLowerCase()
          .indexOf(valorBusqueda.toLowerCase()) !== -1
    )
    .slice((paginaActual - 1) * 12, paginaActual * 12)
    .map((terapeuta, key) => (
      <tr key={key}>
        <th scope='row'>
          <Avatar rutaAvatar={terapeuta.avatar} />
        </th>
        <td>
          <Link
            to={{
              pathname: `/panel/terapeutas/${terapeuta._id}`,
              state: { terapeuta }
            }}
            className={styles.link_info}
          >
            {terapeuta.nombreCompleto}
          </Link>
        </td>
        <td>{parseDateAAAAMMDDtoDDMMAAAA(terapeuta.fechaNacimiento)}</td>
        <td>{terapeuta.email}</td>
        {rolUsuario === 'Admin' ? (
          <td>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  El terapeuta podrá ser eliminado únicamente por un usuario con
                  rol Superadmin
                </Tooltip>
              }
            >
              <>
                <button className={styles.customButton} disabled>
                  <IoMdTrash
                    color='#dbdbdb'
                    size={25}
                    className={styles.customSvg}
                  />
                </button>
                <Link
                  to={{
                    pathname: `/panel/terapeutas/${terapeuta._id}`,
                    state: { terapeuta, isEdit: true }
                  }}
                >
                  <FaRegEdit color='#0079e1' size={25} />
                </Link>
              </>
            </OverlayTrigger>
          </td>
        ) : (
          <td>
            <button
              className={styles.customButton}
              onClick={() => handleEliminar(terapeuta)}
            >
              <IoMdTrash
                color='#7f7f7f'
                size={25}
                className={styles.customSvg + ' ' + styles.customSvgDelete}
              />
            </button>
          </td>
        )}
      </tr>
    ))

  // Functions

  const actualizarValorBusqueda = e => {
    setValorBusqueda(e.target.value)
    setPaginaActual(1)
  }

  const handleEliminar = terapeuta => {
    setTerapeutaSeleccionado(terapeuta)
    setModalEliminar(true)
  }

  const borrarTerapeuta = async () => {
    setSubmitDelete(true)
    let data = await bajarTerapeuta(terapeutaSeleccionado._id)
    setModalEliminar(false)

    if (data.ok) {
      setSubmitDelete(false)
      setAlertSucces(true)
      setTimeout(() => {
        setAlertSucces(false)
      }, 3000)
      setTerapeutasIniciales(
        terapeutasIniciales.filter(t => t._id !== terapeutaSeleccionado._id)
      )
    } else {
      setSubmitDelete(false)
      setAlertFail(true)
      setTimeout(() => {
        setAlertFail(false)
      }, 3000)
    }
  }

  const handleGoInfoTerapeuta = terapeuta => {}

  useEffect(() => {
    let traerTerapeutas = async () => {
      setSpinner(true)
      let data = await obtenerTerapeutas()
      if (data.ok) {
        setSpinner(false)
        setTerapeutasIniciales(data.terapeutas)
      } else {
        setSpinner(false)
        alert('Error al importar los terapeutas')
      }
    }
    traerTerapeutas()
  }, [])

  // Render

  return (
    <div className='dashboardContainer'>
      <DashboardTitle title='Terapeutas' />

      {terapeutasIniciales.length > 0 && (
        <Animated animationIn='fadeIn'>
          <div className={styles.searchContainer + ' row'}>
            <div className={'col-md-7 col-sm-12 ' + styles.searchBoxCon}>
              <Buscador
                placeholder='Buscar terapeutas por nombre o apellido...'
                valor={valorBusqueda}
                handler={actualizarValorBusqueda}
              />
            </div>
          </div>
        </Animated>
      )}

      <Alert variant='success' show={alertSuccess}>
        <strong>Eliminación exitosa.</strong> Se ha eliminado a{' '}
        {terapeutaSeleccionado.nombreCompleto}
      </Alert>
      <Alert variant='danger' show={alertFail}>
        <strong>Eliminación fallida.</strong> No se ha podido eliminar a{' '}
        {terapeutaSeleccionado.nombreCompleto}{' '}
      </Alert>

      {isLoading ? (
        <Spinner />
      ) : terapeutasIniciales.length === 0 ? (
        <EmptyState
          ruta='/images/undraw.svg'
          titulo='No hay terapeutas creados.'
          leyenda='Los terapeutas deberán crear su perfil en la pantalla de inicio, haciendo click en el botón “Registrate aquí”.'
          leyenda2='Recordá que aquí solo se mostraran todos aquellos terapeutas cuyo usuario fue aprobado por el administrador.'
        />
      ) : arrayFilas.length === 0 ? (
        <div style={{ marginTop: '98px' }}>
          <EmptyState
            ruta='/images/vacio.svg'
            subtitulo={`No se encontraron terapeutas con "${valorBusqueda}"`}
          />
        </div>
      ) : (
        <Grilla
          titulos={[
            'Avatar',
            'Nombre Completo',
            'Fecha de nacimiento',
            'Correo electrónico',
            'Acciones'
          ]}
          filas={arrayFilas}
        />
      )}

      {arrayFilas.length !== 0 ? (
        <Paginacion
          cantidad={cantidadPaginas}
          paginaActual={paginaActual}
          handler={setPaginaActual}
        />
      ) : (
        <></>
      )}
      {modalEliminar && (
        <ModalPropio
          title='Eliminar Terapeuta'
          content={`Está seguro que quiere eliminar al terapeuta ${terapeutaSeleccionado.nombreCompleto}? Se podrían perder datos guardados`}
          isOpen={modalEliminar}
          closeModal={setModalEliminar}
          okHandler={borrarTerapeuta}
          isSubmitting={isSubmittingDelete}
          danger={true}
          botonPrimario='Eliminar'
          botonSecundario='Cancelar'
        />
      )}
    </div>
  )
}

export default DashboardTerapeuta
